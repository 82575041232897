






















import {Component, Prop, Vue} from 'vue-property-decorator';
import { LayerList } from '@/types'

@Component
export default class Layers extends Vue {
  @Prop() readonly layers!: LayerList[];
  @Prop() readonly activeLayer!: LayerList;

  visibleOnMobile = false
}
