import * as L from 'leaflet'
import 'leaflet.vectorgrid'

L.PkkRegions = L.VectorGrid.Protobuf.extend({

    initialize: function() {

        this._url = 'https://pkk.rosreestr.ru/arcgis/rest/services/Hosted/caddivsion/VectorTileServer/tile/{z}/{y}/{x}.pbf';

        let options = {}

        options.vectorTileLayerStyles = {
            'Кадастровые кварталы': {
                'fill': ![],
                'color': "#be4341",
                'weight': 2
            },
            'Кадастровые кварталы/label': {
                'fill': ![],
                'opacity': 0,
                'fillOpaticy': 0
            },
            'Кадастровые районы': {
                'fill': ![],
                'color': "#be4341",
                'weight': 2
            },
            'Кадастровые районы:1': {
                'fill': ![],
                'color': "#be4341",
                'weight': 2
            },
            'Кадастровые районы:2': {
                'fill': ![],
                'color': "#be4341",
                'weight': 2
            },
            'Кадастровые районы/label': {
                'fill': ![],
                'opacity': 0,
                'fillOpaticy': 0
            },
            'Кадастровые районы:1/label': {
                'fill': ![],
                'opacity': 0,
                'fillOpaticy': 0
            },
            'Кадастровые районы:2/label': {
                'fill': ![],
                'opacity': 0,
                'fillOpaticy': 0
            },
            'Кадастровые округа': {
                'fill': ![],
                'color': "#be4341",
                'weight': 2
            },
            'Кадастровые округа:1': {
                'fill': ![],
                'color': "#be4341",
                'weight': 2
            },
            'Кадастровые округа:2': {
                'fill': ![],
                'color': "#be4341",
                'weight': 2
            },
            'Кадастровые округа/label': {
                'fill': ![],
                'opacity': 0,
                'fillOpaticy': 0
            },
            'Кадастровые округа:1/label': {
                'fill': ![],
                'opacity': 0,
                'fillOpaticy': 0
            },
            'Кадастровые округа:2/label': {
                'fill': ![],
                'opacity': 0,
                'fillOpaticy': 0
            }
        }

        options.rendererFactory =  L.svg.tile

        // options.maxZoom = 14

        L.VectorGrid.prototype.initialize.call(this, options);
    },

    _getVectorTilePromise: function (coords) {
        coords = this._getCoords(coords);
        return L.VectorGrid.Protobuf.prototype._getVectorTilePromise.call(this, coords);
    },

    _getCoords: function (coords) {

        let zoom = coords.z

        if (zoom > 11) {
            zoom = zoom - 3
            this.options.tileSize = 2048
        } else if (zoom > 8) {
            zoom = zoom - 2
            this.options.tileSize = 1024
        } else {
            zoom = zoom - 1
            this.options.tileSize = 512
        }

        coords.z = zoom

        return coords;
    }

});

L.pkkRegions = function (type, options) {
    return new L.PkkRegions(type, options);
};
