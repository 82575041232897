




















































import Component, {mixins} from 'vue-class-component'
import {ObjectProperty, PkkFeature, PkkObjectAttrs, ObjectArray} from "@/types";
import Mixins from "@/mixins";

@Component
export default class ObjectPanel extends mixins(Mixins) {

  object: PkkFeature | null = null
  visible = true

  get title(): string {
    if (this.object) {
      return 'Найден объект:'
    }
    return 'Введите запрос или кликните по объекту на карте'
  }


  get url(): string | null {

    let baseUrl = 'https://egrnka.info/object/'
    let cadnum = ''

    if (this.object) {
      cadnum = this.cadnumNormalizer(this.object.attrs.cn).replaceAll(':', '-')
    }

    return baseUrl + cadnum
  }

  get filteredObjectProperties(): Array<ObjectProperty | undefined> {

    if (!this.object) return []

    let attrs: PkkObjectAttrs = this.object.attrs

    let properties: Array<ObjectProperty | undefined> = [
      {
        key: 'cn',
        label: 'Кадастровый номер'
      },
      {
        key: 'address',
        label: 'Адрес'
      },
      {
        key: 'area_value',
        label: 'Площадь',
        unit: this.getAreaUnit(this.object.attrs.area_unit)
      },
      {
        key: 'cad_cost',
        label: 'Кадастровая стоимость',
        color: 'red'
      }
    ]

    properties = properties.map(property => {
      if (property && attrs && property.key in attrs) {

        if (attrs[property.key]) {
          property.value = attrs[property.key]

          if (property.key == 'cad_cost') {
            property.value = property.value.toLocaleString('ru-RU') + ' руб.'
          }
        } else {
          property.value = '-'
        }

        return property
      }
    })

    return properties
  }

  close(): void {
    this.visible = false
  }

  getAreaUnit(code: string): string {

    let areaUnits: ObjectArray = {
      "003": "мм",
      "004": "см",
      "005": "дм",
      "006": "м",
      "008": "км",
      "009": "Мм",
      "047": "морск. м.",
      "050": "кв. мм",
      "051": "кв. см",
      "053": "кв. дм",
      "055": "кв. м",
      "058": "тыс. кв. м",
      "059": "га",
      "061": "кв. км",
    }

    return areaUnits[code];

  }

  getObjectType(): string {

    if (!this.object) return ''

    if (this.object.attrs.oks_type && this.object.attrs.purpose) {
      let purposeTypes: ObjectArray = {
        204001e6: "Нежилое здание",
        204002e6: "Жилой дом",
        204003e6: "Многоквартирный дом"
      }
      return purposeTypes[this.object.attrs.purpose];
    }

    if(this.object.attrs.parcel_type == 'parcel' && this.object.attrs.category_type) {
      let categoryTypes: ObjectArray = {
        "003001000000": "Земли сельскохозяйственного назначения",
        "003002000000": "Земли населённых пунктов",
        "003003000000": "Земли промышленности, энергетики, транспорта, связи, радиовещания, телевидения, информатики, земли для обеспечения космической деятельности, земли обороны, безопасности и земли иного специального назначения",
        "003004000000": "Земли особо охраняемых территорий и объектов",
        "003005000000": "Земли лесного фонда",
        "003006000000": "Земли водного фонда",
        "003007000000": "Земли запаса",
        "003008000000": "Категория не установлена"
      }
      return categoryTypes[this.object.attrs.category_type];
    }


    return ''

  }

  setObject(object: PkkFeature): void {
    this.object = object
    this.visible = true
  }

  goToGeomer(): void {
    window.open('https://geomergroup.ru/', '_blank');
  }

}
