







































import Component, { mixins } from 'vue-class-component'
import {Prop} from 'vue-property-decorator';
import {Suggestion, DadataSuggestion, DadataSuggestionResponse} from "@/types";
import axios from "axios";
import Mixins from "@/mixins";

@Component
export default class Search extends mixins(Mixins) {
  @Prop({default: ''}) readonly queryText!: string

  query = ''
  suggestions: Array<Suggestion | DadataSuggestion> = []
  suggestionsVisible = false
  suggestionsType = ''

  get suggestionsTitle(): string {

    let title = ''

    if(this.suggestions.length > 0) {
      title = 'Выберите из списка:'
    } else {
      title = 'Нет результатов'
    }

    return title
  }

  setQuery(query: string): void {
    this.query = query
  }

  loadSuggestions(): void {

    const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'
    const token = 'ace0f9445a4e997c57efb5b8e88baacb2b5681bf'

    axios.post<DadataSuggestionResponse>(url, {
      query: this.query
    }, {
      headers: {
        Authorization: 'Token ' + token
      }
    })
        .then(response => response.data.suggestions)
        .then(response => {
          this.suggestions = response
        })

  }

  onClickSuggestion(suggestion: Suggestion | DadataSuggestion): void {
    this.suggestionsVisible = false

    if(this.suggestionsType == 'address' && 'data' in suggestion) {
      let lat: number = parseFloat(suggestion.data.geo_lat)
      let lng: number = parseFloat(suggestion.data.geo_lon)

      this.setQuery(suggestion.value)

      this.$emit('select-address', lat, lng)
    }
    if(this.suggestionsType == 'cadnum') {
      this.$emit('select-cadnum', this.suggestions[0].value.trim())
    }
  }

  onInput(): void {

    if(this.query === '') {
      this.suggestionsType = ''
      this.suggestionsVisible = false
      this.suggestions = []
      return
    }

    if(this.stringIsCadnum(this.query)) {
      this.suggestionsType = 'cadnum'
    } else {
      this.suggestionsType = 'address'
    }

    this.suggestionsVisible = true

    if(this.suggestionsType == 'address') {
      this.loadSuggestions()
      return;
    }

    if(this.suggestionsType == 'cadnum') {

      this.suggestions = [
        {
          value: this.query
        }
      ]

      return;

    }

  }

  clear(): void {
    this.query = ''
  }

}
