import Vue from 'vue'
import Component from 'vue-class-component'

// Define a super class component
@Component
export default class Mixins extends Vue {

    stringIsCadnum(string: string): boolean {
        return /^[0-9]+:[0-9]+:[0-9]+:[0-9]+$/.test(string)
    }

    stringIsFias(string: string): boolean {
        return /^[a-z0-9]+-[a-z0-9]+-[a-z0-9]+-[a-z0-9]+-[a-z0-9]+$/.test(string)
    }

    cadnumNormalizer(cadnum: string): string {
        let cadnumArray: string[] = cadnum.split(':')

        cadnumArray = cadnumArray.map(value => {
            return parseInt(value).toString()
        })

        return cadnumArray.join(':')
    }

}
