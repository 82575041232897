import * as L from 'leaflet'

L.Rosreestr = L.TileLayer.extend({

    initialize: function() {

        let url = 'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreObjects/MapServer/export?'
        url += 'layers=show%3A30%2C27%2C24%2C23%2C22&dpi=96&format=PNG32&bbox={bbox}&bboxSR=102100&imageSR=102100&size=1024%2C1024&transparent=true&f=image'

        let options = {
            'tileSize': 1024,
            detectRetina: true,
            maxZoom: 20,
            zIndex: 999,
            'subdomains': [
                'a',
                'b',
                'c',
                'd'
            ]
        }

        L.TileLayer.prototype.initialize.call(this, url, options)
    },

    'options': { 'tileSize': 1024 },

    'getTileUrl': function (_0x65dc5) {
        var _0x4f3c29 = this['_map']
        var _0x1a45d8 = _0x4f3c29['options']['crs']
        var _0x159b89 = this['options']['tileSize']
        var _0x1fa277 = _0x65dc5["multiplyBy"](_0x159b89)
        var _0x4500cc = _0x1fa277["add"]([
            _0x159b89,
            _0x159b89
        ]);

        var _0x46b7f1 = _0x1a45d8["project"](_0x4f3c29['unproject'](_0x1fa277, _0x65dc5['z']))
        var _0x27016a = _0x1a45d8['project'](_0x4f3c29["unproject"](_0x4500cc, _0x65dc5['z']))

        var rosreestr_offset_x = 0
        var rosreestr_offset_y = 0

        var _0x3f2180 = [
            _0x46b7f1['x'] - rosreestr_offset_x,
            _0x27016a['y'] - rosreestr_offset_y,
            _0x27016a['x'] - rosreestr_offset_x,
            _0x46b7f1['y'] - rosreestr_offset_y
        ]["join"]("%2C");

        return L['Util']['template'](this['_url'], L['extend']({
            's': this['_getSubdomain'](_0x65dc5),
            'bbox': _0x3f2180
        }, this['options']));
    }

    // getTileUrl: function (tilePoint) {
    //
    //     let map = this._map,
    //         mapOptionsCrs = this._map.options.crs,
    //         mapOptionsTileSize = this._map.options.tileSize,
    //         arrMultiplyBy = tilePoint.multiplyBy(mapOptionsTileSize),
    //         arrMultiplyByAdded = arrMultiplyBy.add([
    //             mapOptionsTileSize,
    //             mapOptionsTileSize
    //         ]);
    //
    //     let rosreestr_offset_x = 0
    //     let rosreestr_offset_y = 0
    //
    //     let xy1 = mapOptionsCrs.project(map.unproject(arrMultiplyBy, tilePoint['z']))
    //     let xy2 = mapOptionsCrs.project(map.unproject(arrMultiplyByAdded, tilePoint['z']))
    //     let bbox = [
    //             xy1['x'] - rosreestr_offset_x,
    //             xy2['y'] - rosreestr_offset_y,
    //             xy2['x'] - rosreestr_offset_x,
    //             xy1['y'] - rosreestr_offset_y
    //         ].join('%2C')
    //
    //     return L.Util.template(this._url, L.extend({
    //         s: this._getSubdomain(tilePoint),
    //         bbox: bbox
    //     }, this.options));
    // }

});

L.rosreestr = function (type, options) {
    return new L.Rosreestr(type, options);
};
