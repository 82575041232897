<template>
  <div class="zoom">
    <button @click="$emit('map-zoom-in')">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clip-rule="evenodd"/>
      </svg>
    </button>
    <button @click="$emit('map-zoom-out')">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z" clip-rule="evenodd"/>
      </svg>
    </button>
  </div>
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class Zoom extends Vue {
}
</script>

<style scoped lang="scss">
.zoom {
  position: absolute;
  z-index: 999;
  right: 20px;
  top: 60px;

  @media (max-width: 540px) {
    top: 100px;
    right: 10px;
  }

}

button {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  border: none;
  display: block;
  padding: 5px;
  color: #67bd4a;
  margin: 10px 0;
  cursor: pointer;

  &:hover {
    opacity: .5;
  }
}
</style>
